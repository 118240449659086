.footer_main {
    background: #0D0D0D;
    padding: 25px 0px;
}

.footer_img {
    text-align: center;
    margin-bottom: 10px;
}

.gold_mart {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #FFBE37;
    text-transform: uppercase;
    text-align: center;
}

.mart_subtext {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #FFBE37;
    text-align: center;
    padding: 6px 0px;
}

.menu_sub li {
    list-style: none;
    display: inline-block;
    margin-left: 15px;
    color: #FFFFFF;
    font-size: 12px;
    cursor: pointer;
}

.menu_sub li:first-child {
    margin-left: 0px;
}

.menu_display {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    border-bottom: 1px solid #FFFFFF;
    float: left;
    padding: 12px;
        margin-bottom: 14px;
}

.menu_sub {
    padding: 15px 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu_sub1 {
    padding-top: 15px !important;
    display: flex;
    align-items: center;
}

.menu_sub1 li {
    list-style: none;
    display: inline-block;
    font-size: 12px;
    margin-left: 15px;
    color: #FFFFFF;
    float: right;
    cursor: pointer;
    word-break: break-word;
}
.menu_sub1 li a {
    color: rgb(255,255,255);
}

.footer_box {
    padding: 0px;
}

@media (min-width:320px) and (max-width:767px) {
    .menu_sub {
        float: left;
        width: 100%;
    }

    .menu_sub1 li {
        float: left;
        width: 50%;
        margin: 0px;
        text-align: left !important;
        margin-top: 6px;
    }

    .menu_sub1 {
        display: block;
    }
}

.whatsapp_icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999999;
    box-shadow: 0 0 8px rgb(0 0 0 / 57%);
    border-radius: 50%;
}