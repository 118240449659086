.privacy{
    padding: 40px;
    min-height: calc(100vh - 442px);
    word-break: break-word;
    text-align: justify;
}
.privacyPolicy {
    font-size: 24px;
    color: #400000;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .privacy{
        padding: 40px 20px;
    }
}