.login_main{
    background-image: url("../../../assets/images/pop_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
        display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
        padding: 50px;
        min-height: calc(100vh - 404px);
}
.login_maindiv{
    /* position: absolute; */
}
.welcome {
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    text-transform: uppercase;
    color: #C68100;
    border-bottom: 1px solid #C68100;
    padding: 15px 0px;
}
.login_form{
    background: #FFFFFF;
box-shadow: 0px 0px 20px rgba(193, 177, 188, 0.1);
border-radius: 8px;
    padding: 8px 34px;
    width: 557px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.mob_number{
    font-style: normal;
font-weight: 300;
font-size: 15px;
color: #000000;
padding: 15px 0px;

}
.mob_number input{
    outline: none;
    border: none;
border-bottom: 1px solid #C7C7C7;
width: 100%;
margin-top: 8px;
}
.forgot_password{
    color: #000000;
    font-style: normal;
font-weight: 400;
font-size: 12px;   
float: right !important; 
text-align: right;
width: 100%;
margin: 0px 0px 18px;
}
.forgot_password_reg{
   margin: 18px 0px; 
       color: #000000;
    font-style: normal;
font-weight: 400;
font-size: 12px;   
}
.forgot_password span{
    cursor: pointer;
    color: #C68100;
}
.forgot_password_reg span{
    cursor: pointer;
    color: #C68100;
}
.login{
    background: #000000;
    font-size: 15px;
    font-style: normal;
font-weight: 400;
    align-items: center;
    border: none;
    text-align: center;
    outline: none;
    color: #FFFFFF;
}
.login_form .row{
    align-items: center;

}
.mob_number label{
    margin: 0px;
}
.login_btn{
    margin-top: 20px;
}
.login_btn button{
    width: 100%;
    padding: 10px 0px;
}
.image_sub img {
    width: 100%;
}
.image_sub {
    position: relative;
}
.image_sub::before {
    content: '';
    height: 75px;
    width: 1px;
    background-color: #C68100;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;
}
.image_sub::after {
    content: '';
    height: 70px;
    width: 1px;
    background-color: #C68100;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 70px;
}
.mob_number input::placeholder {
    color: #3c3c3c6b;
    font-size: 12px;
}
@media (max-width:767){
    .login_form{
        width: 228px;
    }
}
@media (min-width:320px) and (max-width:479px){
   .login_form{
        width: 270px;
    }
    .welcome{
        font-size: 18px;
            padding: 6px 0px;
    }
    .mob_number{
        font-size: 12px;
    }
    .image_sub{
        display: none;
    }
    .menu_sub1 li{
   
        text-align: center;
    }
    .menu_sub li{
        margin-left: 10px !important;
    }
}
