.cart_img img{
width: 100%;
}
.main_cart {
    padding: 40px;
    min-height: calc(100vh - 404px);
}
.quantity button {
    width: 100%;
    padding: 10px;
        background-color: #400000;
    color: white;
    border: none;
    text-align: center;
}
.detail_note1{
    text-align: right;
}
.cartData{
    font-size: 24px;
    color: #400000;
    margin-bottom: 10px;
}
.cart_detail_box {
    border: 1px solid #40000021;
}
.order_sum {
    border: 1px solid #40000021;
}
@media (max-width:768px){
         .cart_detail_box{
        margin: 22px 0px;
    }
   
}
.cartEmpty {
    padding: 30px;
    text-align: center;
    min-height: calc(100vh - 442px);
}
.cartempty_txt {
    font-size: 22px;
    font-weight: 700;
    padding: 13px;
        color: #400000;
}


