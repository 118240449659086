@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.product_container {
    padding: 40px 0px;
}
.product_bar {
    background-color: rgba(247, 247, 247, 1);
    padding: 10px;
}
.product_bar input {
   border: 1px solid rgba(247, 247, 247, 1);
    border-radius: 18px;
    font-family: 'FuturaNormal';
    font-size: 15px;
    font-weight: 400;
    color: rgb(0,0,0);
    line-height: 17px;
    float: left;
    width: 100%;
    padding: 10px;
    outline: none;
    padding-left: 40px;
}
.product_bar input::placeholder{
    color: rgba(165, 163, 163, 1);
}

.product_bar span {
    float: left;
    width: 100%;
    position: relative;
}
.product_bar span::before {
    content: '\f002';
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    /* background-image: url("../../../assets/images/pink.png"); */
    height: 18px;
    width: 18px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    left: 12px;
    top: 8px;
    color: rgb(189 189 189);
}



.product_box {
    background-color: rgba(247, 247, 247, 1);
    padding: 20px;
    margin-top: 40px;
    cursor: pointer;
}
.product_image {
    margin-bottom: 20px;
}
.product_desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product_name {
font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 17px;
    text-transform: uppercase;
    width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.product_rate {
   font-family: 'Roboto',sans-serif;
font-style: normal;
font-weight: 500;
font-size: 14px;
  color: #333333;
    line-height: 17px;
    width: 50%;
    text-transform: uppercase;
    text-align: right;
}
.product_rate span {
    font-family: 'FuturaNormal';
}
.product_weight {
    font-family: 'FuturaNormal';
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    line-height: 17px;
    text-transform: uppercase;
    margin-top: 10px;
}
.product_image img {
    width: 100%;
}

.product_box {
    position: relative;
}
.heart {
    width: 30px;
    height: 30px;
    text-align: center;
    color: rgb(203 203 203);
    background-color: rgb(247 247 247);
    line-height: 34px;
    border-radius: 50%;
    position: absolute;
    font-size: 18px;
    box-shadow: 0px 0px 5px rgb(215 215 215 / 49%);
    right: 30px;
    top: 30px;
    cursor: pointer;
}
.heart_red {
    color: rgb(243 33 0) !important;
}
.breadcrumbs {
    font-family: 'FuturaNormal';
    font-size: 12px;
    font-weight: 400;
    color: rgba(115, 115, 115, 1);
    line-height: 17px;
    margin-bottom: 15px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    /* padding: 0px 25px; */
    cursor: pointer;
}
.breadcrumbs span {
     color: rgba(115, 115, 115, 1);
}
.filter_head {
    padding: 10px 0px;
    border-bottom: 1px solid rgba(231, 231, 231, 1);
}
.filter_head span {
   font-family: 'Roboto',sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
  color: #2D2A2A;
    text-transform: uppercase;
    text-decoration: none !important;
    float: left;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.3s all ease-in-out;
}
.filter_head a:hover{
      color: #2D2A2A;
}
.filter_head a.collapsed i {
    transform: rotate(
360deg
);
    transition: 0.2s all ease-in-out;
}

.filter_head a i {
    transform: rotate( 
180deg
 );
    transition: 0.2s all ease-in-out;
}

.filter_cont {
    padding-top: 24px;
}

  .filter_cont .form-group {
    display: block;
    margin-bottom: 16px;
  }
  
  .filter_cont .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .filter_cont .form-group label {
      position: relative;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #727272;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    line-height: 26px;
}
  
  .filter_cont .form-group label:before {
   content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid rgba(64, 0, 0, 1);
    border-radius: 1px;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;

  }
  
  .filter_cont .form-group input:checked + label:after {
     content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    background-image: url("../../../assets/images/check.png");
    left: 3px;
    top: 6px;
    background-size: cover;
    background-position: center;
  }

.filter_input {
    display: flex;
    align-items: center;
}
.filter_input span {
    width: 40%;
    margin-right: 15px;
    position: relative;
}
.filter_input input {
    float: left;
    width: 100%;
    border: 1px solid rgba(110, 110, 110, 1);
    border-radius: 5px;
    font-family: 'FuturaNormal';
    color: rgb(0,0,0);
    padding: 15px 15px 15px 35px;
    font-size: 18px;
    outline: none;
}
.filter_input input::placeholder {
    color: rgba(182, 182, 182, 1);
}
.filter_input span::before {
    content: '\e1bc';
    font-weight: 600;
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}
.filter_input button {
    font-family: 'FuturaNormal';
    font-size: 18px;
    font-weight: 400;
    color: rgb(255 255 255);
    background-color: rgba(0, 0, 0, 1);
    border-radius: 5px;
    border: none;
    width: 20%;
    padding: 15px;
}

.pagination {
    justify-content: flex-end;
    margin-top: 40px !important;
}

.pagination li {
    margin-left: 10px;
}



.pagination li:first-child a,
.pagination li:last-child a {
    width: auto;
    height: auto;
    border: none;
    text-transform: uppercase;
}


/* .filter_cont {
    overflow: hidden;
    transition: max-height 0.6s ease;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


/* 
.filter_cont {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
}

.filter_cont.show {
    transition: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}
 */
 .filter_btn {
    background-color: rgba(64, 0, 0, 1);
    border: none;
    color: rgb(255,255,255);
    font-size: 13px;
    padding: 5px 15px;
    box-shadow: 0px 0px 4px rgb(68 68 68 / 60%);
    border-radius: 3px;
}
.filter_btn i {
    margin-right: 5px;
    font-size: 12px;
}

@media (max-width: 767px) {
    .pagination {
        display: block !important;
    }

    .pagination li {
        display: inline-block !important;
        margin-bottom: 5px !important;
    }

    .product_filter_cont {
        padding: 10px 20px;
        max-height: 400px;
        overflow: auto;
    }

    .product_filter {
        /* position: fixed;
        bottom: 0px;
        border-radius: 15px 15px 0px 0px;
        box-shadow: 0px -3px 6px rgb(160 160 160 / 40%);
        z-index: 11;
        overflow-y: auto;
        max-height: 0; */
        background-color: rgb(255 255 255);
        left: 0px;
        width: 100%;
        transition: 0.9s all ease-in-out;
        display: none;
    }
    .filter_display {
        display: block;
        transition: 0.9s all ease-in-out;
    }
    .filter_show {
        transition: 0.9s all ease-in-out;
        height: auto;
        max-height: 450px;
    }
    .product_filter_close {
        border: none;
        background-color: rgb(0 0 0);
        border-top: 1px solid rgb(180, 180, 180);
        text-align: center;
        color: rgb(255,255,255);
        font-size: 15px;
        font-family: 'FuturaNormal';
        padding: 10px;
        text-align: center;
    }
    .flex_center {
        display: flex;
        align-items: center;
    }
    .filler {
        flex-grow: 1;
    }
    .product_filter_open {
        border: none;
        background-color: rgb(0 0 0);
        border-top: 1px solid rgb(180, 180, 180);
        color: rgb(255,255,255);
        font-size: 15px;
        font-family: 'FuturaNormal';
        padding: 5px 15px;
        text-align: center;
        border-radius: 2px;
    }
    .modal-shadow {
        position: relative;
        overflow: hidden;
    }
    .modal-shadow::before {
        position: fixed;
        content: '';
        background-color: rgb(0 0 0 / 40%);
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100vh;
        z-index: 10;
    }
    .product_container {
    padding: 24px 0px;
}
}


.collection_banner img {
    width: 100%;
}
.product_list{
    position: relative;
         min-height: 300px;

}
.product_loader {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 90%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
        min-height: 300px;

}
.product_loader img {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 60px);
    animation: zoom-in-zoom-out 1s ease infinite;
}
.nodata_text {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
}
.no_data_img{
    padding: 20px;
}
.nodata {
    text-align: center;
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(0.8, 0.8);
    }

    100% {
        transform: scale(1, 1);
    }
}


@media (max-width: 767px) {
    .product_left {
        padding: 0px 5px;
    }
    .nodata img {
    width: 100%;
}
}

@media (min-width: 768px) and (max-width: 991px) {

.product_container .col-md-10 {
    flex: 0 0 75%;
    max-width: 75%;
}
.product_container .col-md-2 {
    flex: 0 0 25%;
    max-width: 25%;
}
.product_list .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
}
.product_name {
    font-size: 12px;
}
.product_rate {
    font-size: 12px;
}
.product_weight {
    font-size: 12px;
}
}

@media (min-width: 992px) and (max-width: 1199px) {
    .product_container .col-md-10 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .product_container .col-md-2 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .product_list .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}