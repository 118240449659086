.middlebar {
    background-color: rgba(64, 0, 0, 1);
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    text-decoration: none;
    color: rgb(255,255,255);
    padding: 10px 20px !important;
    display: flex;
    align-items: center;
    margin: 0px;
}
.middlebar li {
    list-style-type: none;
    padding: 0px 20px;
    position: relative;
}
.middlebar li.filler::before {
    display: none;
}
.middlebar li::before {
    content: '';
    height: 10px;
    width: 1px;
    position: absolute;
    background-color: rgb(255,255,255);
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
}
.filler {
    flex-grow: 1;
}
.middlebar li:first-child::before, .middlebar li:last-child::before {
display: none;
}
.middlebar li a {
    color: rgb(255 190 55);
}
.middlebar .overlay {
    display: none;
}

@media (max-width: 767px) {

.middlebar li {
    display: inline-block;
}
.middlebar li.filler {
    display: none;
}
}