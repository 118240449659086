.details{
    color: #000000;
    font-style: normal;
font-weight: 300;
font-size: 15px;
padding: 18px 0px 4px;
}
.welcome_reg{
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    text-transform: uppercase;
    color: #C68100;
    border-bottom: 1px solid #C68100;

}
.login_form_reg{
 padding: 34px;
}
.mob_numbers{
    margin-top: 26px;
}
.image_sub1{
position: relative;
}
.image_sub1::before{
  content: '';
    height: 234px;
    width: 1px;
    background-color: #C68100;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;
}
.image_sub1::after{
        content: '';
    height: 234px;
    width: 1px;
    background-color: #C68100;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 70px;
}
.enquiry_head {
    font-family: "FuturaNormal";
    font-size: 25px;
    font-weight: 400;
    color: #000;
    margin-bottom: 22px;
    text-align: center;
}
.enquiry_cont .form-group input {
    font-family: "FuturaNormal";
    font-size: 16px;
    font-weight: 400;
    color: #000;
    line-height: 23px;
    border: 1px solid #767676;
    border-radius: 5px;
    outline: none;
    float: left;
    width: 100%;
    padding: 16px 20px;
}
.mode_btn {
    text-align: center;
    margin-top: 35px;
}
.mode_btn button {
    font-family: "FuturaNormal";
    font-size: 20px;
    color: #fff;
    line-height: 13px;
    background-color: #000;
    border: none;
    border-radius: 4px;
    padding: 18px 70px;
}
.modalPop {
    position: fixed;
    background-color: rgb(105 93 93 / 60%);
    z-index: 1050;
    width: 100%;
    height: 100vh;
    transition: all .5s ease-in-out;
    top: 0;
    left: 0;
    overflow: auto;
}

@media (max-width: 767px) {
    .image_sub1:after, .image_sub1:before {
        display: none;
    }
    .image_sub1{
        display: none;
    }
    .details{
    font-size: 12px;
    }
}