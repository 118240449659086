.contact_main{
    padding: 40px 0px;
}
.map_show iframe {
    width: 100%;
}
.submit_btn button{
    width: 100%;
        background-color: #400000;
    padding: 10px;
    color: white;
    margin-top: 10px;
    border: none;
}

.address_head {
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    text-transform: uppercase;
    color: #C68100;
    border-bottom: 1px solid #C68100;
    padding: 15px 0px;
    margin-bottom: 25px;
}
.address_box li {
    list-style-type: none;
    margin-bottom: 15px;
    font-size: 15px;
    position: relative;
    padding-left: 30px;
}
.address_box li::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    position: absolute;
    left: 0px;
    top: 0px;
    color: rgb(198 129 0);
}
.address_box li:nth-child(1):before {
    content: '\f879';
}
.address_box li:nth-child(2):before {
    content: '\f0e0';
}
.address_box li:nth-child(3):before {
    content: '\f3c5';
}