.menu_image {
    width: 100%;
    text-align: center;
}

.cart_section li {
    list-style: none;
    display: inline-block;
    position: relative;
    padding: 10px 0px;
}

.cart_section1 li b {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: rgb(0, 0, 0);
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    font-size: 12px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 50%);
}


.cart_section li:last-child {
    margin-left: 20px;
}

.cart_section li i {
    width: 30px;
    height: 30px;
    background-color: rgba(64, 0, 0, 1);
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    font-size: 14px;
}

.align-center {
    display: inline-block;
    text-align: center;
}

.nav-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: normal;

}

.menuH li {
    list-style: none;
    display: inline-block;
    margin: 0px 12px;
    font-weight: 400;
    font-size: 15px;
    color: rgb(51 51 51);
    cursor: pointer;
}

ul.cart_section {
    margin-bottom: 0px;
}
.menu_main {
    box-shadow: 0px 0px 5px rgb(64 0 0 / 20%);
}
.menu_main .row {
    align-items: center;
}
.logo img {
    cursor: pointer;
}

/* .menuH_container{
    display: none;
} */
/* .menubar{
    display: none;
} */

.header_main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}

.menuH li {
    list-style: none;
    display: inline-block;
    margin: 0px 12px;
}

.submenu {
    position: absolute;
    left: -45px;
    top: 45px;
    width: 140px;
    background-color: rgb(255,255,255);
    box-shadow: 0px 0px 6px rgb(68 68 68 / 40%);
    border-radius: 2px;
    border-top: 3px solid rgba(64, 0, 0, 1);
    transform: translateY(70px);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s all ease-in-out;
    z-index: 1;
}
.cart_section li:hover .submenu {
    transform: translateY(0px);
    transition: 0.5s all ease-in-out;
    opacity: 1 !important;
    visibility: visible !important;
    cursor: pointer;
}
.cart_section li:first-child {
    margin-right: 20px;
}


.submenu li {
    float: left;
    width: 100%;
    text-align: left;
    margin: 0px !important;
    padding: 10px 15px;
    font-size: 13px;
    color: rgb(51,51,51);
    border-bottom: 1px solid rgb(229 229 229);
}
.submenu li:last-child {
    border-bottom: none !important;
}
.dailyrate {
    width: 180px !important;
}

.dailyrate li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



@media (max-width: 767px) {
    .menuH_container {
        display: block;
    }

    .mobileMenu_btn {
        float: right;
        padding: 10px;
        /* background-color: #40000030; */
        background-color: #40000024;
        width: 188px;
    }

    .mobileMenu_btn span {
        float: right;
    }

    .header {
        float: right;
        text-align: left;
        width: 188px;
        background-color: #40000024;
        padding: 10px 16px;
    }

    .menubar {
        position: absolute;
    }

    /* #mobileMenu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background-color: rgb(255 255 255);
    box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 24%);
} */

.menuH {
    position: fixed;
    right: 0px;
    top: 0px;
    background-color: rgb(255 255 255);
    width: 0;
    height: 100vh;
    z-index: 1;
    transition: 0.5s all ease-in-out;
    border-top: 4px solid rgba(64, 0, 0, 1);
}

.toggle_bar {
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0px;
    margin-right: 20px;
}
.toggle_bar span {
    float: left;
    width: 100%;
    height: 2px;
    background-color: rgba(64, 0, 0, 1);
    transition: 0.5s all ease-in-out;
}
.fixed_menu {
    width: 260px;
    transition: 0.5s all ease-in-out;
}
.menuH li {
    float: left;
    width: 100%;
    padding: 10px 20px;
    margin: 0px;
    border-bottom: 1px solid rgb(242 242 242);
}
.menu_main {
    height: 84px;
    border-bottom: 1px solid rgba(64, 0, 0, 1);
}
.middlebar {
    height: 36px;
}
.toggle_rotate span:nth-child(1) {
    transform: rotate(45deg) translate(3px,8px);
    transition: 0.5s all ease-in-out;
}
.toggle_rotate span:nth-child(2) {
    display: none;
    transition: 0.5s all ease-in-out;
}
.toggle_rotate span:nth-child(3) {
    transform: rotate(-45deg) translate(3px,-9px);
    transition: 0.5s all ease-in-out;
}
}
@media (min-width: 768px) and (max-width: 991px) {
    .middlebar li {
        padding: 0px 5px;
        font-size: 8px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .middlebar li {
        padding: 0px 10px;
        font-size: 10px;
    }
}