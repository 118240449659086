
.thankyou_container {
    padding: 0px 20px;
}

.thankyou_image {
    text-align: center;
}

.thankyou_desk {
    font-family: 'FuturaNormal';
    font-size: 25px;
    font-weight: 400;
    color: rgba(71, 71, 71, 1);
    text-align: center;
    letter-spacing: 0px;
    line-height: 40px;
}
.thankyou_box {
    text-align: center;
    font-size: 26px;
    padding: 10px;
    color: #400000;
}
@media(max-width:767px){
    .thankyou_image img {
    width: 100%;
}
}