@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
.flt{
  float: left;
  width: 100%;
}
.body{
  font-family: 'Roboto', sans-serif;
}
ul {
  padding: 0px !important;
  margin: 0px !important;
}
.pagination li {
    margin-left: 10px;
}
.pagination {
    justify-content: flex-end;
    margin-top: 40px!important;
}
.pagination li a {
    border-radius: 20%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: block;
    border: 1px solid #000;
    text-align: center;
    font-size: 11px;
}
.pagination li.active a {
background-color: #400000;
    color: rgb(255 255 255) !important;
}

.cartpg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    text-align: center;
    background-color: rgb(255 255 255 / 90%);
    z-index: 9;
}

.cartpg img {
    position: fixed;
    left: calc(50% - 50px);
    top: calc(50% - 60px);
    animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(0.8, 0.8);
    }

    100% {
        transform: scale(1, 1);
    }
}

/* Media Query */

@media (max-width: 767px) {
    .hide_mobile {
        display: none !important;
    }
}
@media (min-width: 768px) {
    .hide_desktop {
        display: none !important;
    }
}