.shipping_container {
    padding: 40px 150px 40px 150px;
}

.shipping_head {
   
    font-size: 25px;
    font-weight: 500;
       color: rgb(64 0 0);
    letter-spacing: 0px;
    line-height: 25px;
    margin-bottom: 32px;
}
.shipping_fields .form-group {
    margin-bottom: 38px;
}
.shipping_fields label {
  
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 18px;
    float: left;
    width: 100%;
}
.shipping_fields input {
    font-size: 14px;
    float: left;
    width: 100%;
    border:none;
    border-bottom: 2px solid #400000;
    border-radius: 4px;
    height: 60px;
    outline: none;
    padding: 0px 15px;
        background: #40000008;
}
.shipping_note {
    
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 83, 83, 1);
    line-height: 18px;
    text-transform: uppercase;
    background-color: rgba(255, 234, 234, 1);
    border: 1px solid rgba(245, 0, 0, 1);
    border-radius: 5px;
    padding: 15px 25px;
    width: auto;
    display: flex;
    align-items: center;
}
.shipping_note i {
    color: rgba(245, 0, 0, 1);
    font-size: 28px;
    margin-right: 20px;
}
.shipping_list {
    border-top: 1px dashed rgb(217 216 216);
    padding-top: 25px;
    margin-top: 35px;
}
.shipping_list li {
    list-style-type: none;

    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
}
.shipping_list li span {
    color: rgba(84, 84, 84, 1);
}
.shipping_list li b {
 
    font-weight: 300;
}
.shipping_list li:last-child {
    font-size: 18px;
 
    color: rgba(0, 0, 0, 1);
    border-top: 1px dashed rgb(217 216 216);
    padding-top: 25px;
    border-bottom: 1px solid rgb(217 216 216);
}
.shipping_list li:last-child span {
    color: rgba(0, 0, 0, 1);
}

.shipping_mode [type="radio"]:checked,
.shipping_mode [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.shipping_mode [type="radio"]:checked + label,
.shipping_mode [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    line-height: 22px;
    display: inline-block;
    color: rgb(0,0,0);
    
    font-size: 18px;
}
.shipping_mode [type="radio"]:checked + label:before,
.shipping_mode [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid rgb(0,0,0);
    border-radius: 100%;
    background: rgb(255,255,255);
}
.shipping_mode [type="radio"]:checked + label:after,
.shipping_mode [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: rgb(0, 0, 0);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.shipping_mode [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.shipping_mode [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.shipping_mode {
    margin: 35px 0px;
}
.shipping_mode_head {

    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 18px;
}
.shipping_btn {
 background-color: rgb(64 0 0);
    border-radius: 5px;
    border: none;
    text-align: center;
    color: rgb(255,255,255);
    height: 60px;
    outline: none;
    font-size: 20px;
    font-weight: 500;
}
.form-group select {
    font-size: 14px;
    float: left;
    width: 100%;
    border: none;
    border-bottom: 2px solid #400000;
    border-radius: 4px;
    height: 60px;
    outline: none;
    padding: 0px 15px;
    background: #40000008;
}
@media (max-width:768px){
    .shipping_container {
    padding: 40px 20px 40px 20px;
}
.shipping_btn{
    height: 50px;
    font-size:18px
}
.shipping_list li:last-child{
    font-size: 16px;
}
.shipping_list li{
    font-size: 12px;
}
}