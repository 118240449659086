.myaccount{
    padding: 40px;
}
.mycontact {
    padding: 38px 0px;
}
.mob{
    opacity: 0.5;
    
}
@media (max-width:768px){
    .myaccount {
    padding: 40px 20px;
}
.mycontact {
    padding: 26px 0px;
}
}