
.about_img img{
    width: 100%;
}
.about_main{
    padding: 10px;
}
.about_head {
    font-size: 22px;
    padding: 10px 0px;
}
.abt_container {
    padding: 40px 0px;
}
.about_head {
    font-family: 'Rochester', cursive;
    color: #C08920;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 50px;
    margin-bottom: 10px;
}
.about_body {
    font-family: 'Montserrat', sans-serif;
    color: #333333;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: justify;
}
.about_img img {
    border-radius: 3px;
    box-shadow: 8px 8px 8px rgb(36 36 36 / 35%);
}
@media (min-width:320px) and (max-width:479px){
    .about_main{
    padding: 10px;
}
.contact_main {
    padding: 40px 0px !important;
}
.map_show{
    padding: 4px;
}
.contactMain {
    padding: 18px 0px;
}
}