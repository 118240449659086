.order_img img {
    width: 100%;
}
.invoice button {
    padding: 10px;
    border: 1px solid #400000;
    color: #400000;
}
.mainord {
        margin-bottom: 36px;
            border: 1px solid rgb(220,220,220);
    padding: 10px;
}
.mainord .row {
    align-items: center;
    justify-content: space-between;
}
.invoice button {
    float: left;
    width: 100%;

    font-size: 10px;

}


.order_name{
    font-size: 18px;
     color: #400000;
}
.price{
     font-size: 14px;
     color: #400000;
}
.product_info ul li {
    list-style: none;
    line-height: 23px;
    list-style: none;
    font-size: 12px;
}
.main_order{
    padding: 50px;
}