@import url('https://fonts.googleapis.com/css2?family=Rochester&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');
.disable_wifi
{
    opacity: 0.25;
    pointer-events: none;
}

.main_wifi1_det {
    padding: 0px;
    margin: 0px;
}

.main_wifi1_det li {
    list-style-type: none;
    float: left;
    width: 33.33%;
    margin-bottom: 15px;
}

.main_wifi1_det img {
    margin-right: 5px;
}

.main_wifi1_det span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #545454;
}
.left_view_room li:last-child a{
    display: block;
}
.left_view_room li a{
    display: none;
}
.banner_container img {
    width: 100%;
}
.gold_heading{
    text-align: center;
    padding: 40px 0px 20px 0px;
}
.gold_heading span{
  font-family: 'Rochester', cursive;

    color: #C08920;
    width: 100%;
    font-style: normal;
font-weight: 400;
font-size: 50px;

}
.gold_silver{
    font-family: 'Montserrat', sans-serif;
text-transform: uppercase;
color: #333333;
font-style: normal;
font-weight: 400;
font-size: 15px;
padding: 14px 0px;
}
.gold_image img {
    cursor: pointer;
    width: 100%;
}
.gold_image{
    padding: 0px 15px;
}
.gold_name{
    color: #333333;
    font-style: normal;
font-weight: 400;
font-size: 16px;
padding: 8px 0px;
text-align: center;
  font-family: 'Montserrat', sans-serif;
}
.view_banner img {
    width: 100%;
    margin-top: 50px;
    position: relative;
    cursor: pointer;
}
.ring_main{
    background-image: url("../../../assets/images/pink.png");
    background-repeat: no-repeat;
    background-position:center;
    padding: 80px 15px;
        background-size: cover;
        margin-top: 60px;
}
.ring_box_img img{
    width: 100%;
}
.main_text{
color: #400000;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
font-weight: 400;
font-size: 20px;
padding: 10px 0px;
}
.sub_text{
color: #333333;
font-family: 'Roboto',sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
padding: 10px 0px;
}
.looknew{
    margin-bottom: 60px;
}
.all_prod{
    position: absolute;
    text-decoration-line: underline;
font-family: 'Roboto',sans-serif;
font-style: normal;
font-weight: 400;
font-size: 20px;
color: #FFD27D;
}
@media (min-width:320px) and (max-width:479px){
    .gold_heading span {
        font-size: 30px;
    }
    .gold_silver {
        font-size: 12px;
    }
    .gold_name{
        font-size: 10px;
    }
    .main_text{
        font-size: 16px;
    }
    .sub_text{
        font-size: 12px;
    }
    .gold_heading{
            padding: 20px 0px;
    }
    .ring_main{
        margin-top: 30px;
            padding: 40px 15px;
    }
}
@media (min-width:480px) and (max-width:639px){
  .gold_heading span {
        font-size: 30px;
    }
    .gold_silver {
        font-size: 12px;
    }
    .gold_name{
        font-size: 10px;
    }
    .main_text{
        font-size: 16px;
    }
    .sub_text{
        font-size: 12px;
    }
    .gold_heading{
            padding: 20px 0px;
    }
    .ring_main{
        margin-top: 30px;
            padding: 40px 15px;
    }
}
.catregory_cont .slick-track {
    display: flex;
}
.category_box {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    box-shadow: 3px 6px 4px 0 rgb(0 0 0 / 11%);
    padding: 8px;
    margin: 20px 0px;
    cursor: pointer;
}
.catregory_cont .slick-slide>div {
    padding: 0 13px;
}
.category_box a {
    float: left;
    width: 100%;
}

.category_image img {
    width: 100%;
    border-radius: 5px;
}

.category_name {
    font-size: 16px;
    font-weight: 500;
    color: rgba(64, 0, 0, 1);
    letter-spacing: 0px;
    line-height: 25px;
    text-align: center;
    padding: 14px 0px 6px 0px;
}
